.professionals-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 30px;
  flex-direction: column;
  padding: 15px;
}

@media screen and (min-width: 800px) {
  // this css will only be used when the screen size is min 800px
  .professionals-container {
    padding: 0px 10%;
  }

  .app-header {
    padding: 0px 10%;
  }
}

.professionals-container.loading {
  display: none;
}

