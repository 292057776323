@import "./src/colorDefinitions";

.professional-page-container {
  background-color: white;
  padding: 15px;
}

@media screen and (min-width: 800px) {
  // this css will only be used when the screen size is min 800px
  .professional-page-container {
    padding: 0px 10%;
  }
}

.professional-page-header {
  display: flex;
  justify-content: space-between;

  .right-panel {
    img {
      cursor: pointer;
      margin: 0px 5px;
      width: 27px;
    }
  }
  .left-panel {
    display: flex;
    img {
      cursor: pointer;
      margin: 0px 5px;
      width: 27px;
    }
  }
}

.business-logo-container {
  display: flex;
  justify-content: center;
  img {
    max-height: 100px;
  }
}

.business-category-container {
  margin-top: 10px;
  flex-wrap: wrap;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    background: #F6ECFF;
    padding: 8px
  }
  svg {
    padding: 8px;

    background: #F6ECFF;
    width: 20px;
    height: 20px;
  }
}

.business-key-value-container {
  margin-top: 10px;
  display: flex;
  align-items: center;


  .underline {
    text-decoration: underline;
    cursor: pointer;
  }


  img {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
}

.professional-page-body {
  //overflow-x: auto;
}

.add-to-favorite-container {
  display: flex;
  direction: ltr;

  svg {
    width: 30px;
    height: 30px;
  }
}

.business-name-container {
  display: flex;
  font-size: 23px;
  font-weight: bold;
  margin-top: 20px;
  justify-content: flex-start;
  text-align: right;
  align-items: center;

  .user-logo-icon {
    border-radius: 15px;
  }

  img {
    width: 30px;
    height: 30px;

    margin-left: 14px;
    margin-right: 15px;
  }
}

.professional-page-top-level {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;

  .owner-name-container {
    margin-top: 10px;
    text-align: initial;
    font-size: 20px;
  }
}

.phone-dialog-container {
  text-decoration: none;
  border-radius: 6px;
  border: 0;
  box-shadow: 0 3px 5px 0 rgb(41 49 105 / 29%);
  background-image: linear-gradient(90deg, #42aa21, #58c436);
  font-size: 18px;
  height: 39px;
  justify-content: center;
  align-items: center;
  margin: 8px 16px;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;

  @media only screen and (min-width: 1000px) {
      margin-left: 30%;
      margin-right: 30%;
  }

  svg {
    width: 50px;
    height: 50px;
  }

  svg path {
    fill: white!important;
  }

  .phone-call-icon {
    width: 20px;
    margin-right: 6px;
    font-weight: bold;
  }

  .phone-dialog-button {
    color: white;
  }

}

.services-container {
  margin-top: 10px;
  text-align: start;
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .divider-services {
    width: 10px;
  }
}

.product-location-container {
  display: flex;
  justify-content: flex-start;
}

.product-category-container {
  display: flex;
  justify-content: flex-start;
  text-align: initial;
}

.address-container {
  display: flex;
  justify-content: flex-start;
}

.activity-time-container {
  display: flex;
  justify-content: flex-start;
}

.bold-key {
  font-weight: bolder;
  margin-left: 2px;
  width: 140px;
  text-align: justify;
}

.value-key {
  width: 100%;
}

.bottom-links-container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  bottom: 25px;
  position: fixed;
  z-index: 100;
  width: 100vw;
  right: 0px;



  img {
    cursor: pointer;
  }

  .bottom-link-container {
    img {
      width: 3.5vh;
      height: 3.5vh;
    }
  }


}

.pop-up-container {
  background-color: #3eafe5;
  width: 23%;
  margin: auto;
  border-radius: 6px;
}

.found-bug-container {
  display: flex;
}

.ministry-logo-icon {
  width: 50px;
  height: 50px;
  border-radius: 15px;
}