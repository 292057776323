.choose-tenant-container {
  display: flex;
  flex-wrap: wrap;
  .tenant-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.35);
    border: 1px solid #fafafa;
    margin: 8px 5px;
    width: 25%;
    min-height: 30px;
    border-radius: 10px;
    padding: 7px;
    justify-content: space-between;


    img {
      width: 50px;
    }
  }
}