@import "./src/colorDefinitions";

.search-and-filters-container {
  margin-top: 10px;
  margin-top: 10px;
}

.categories-filter-container{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
  direction: rtl;
  -webkit-overflow-scrolling: unset;
  align-items: center;
  justify-content: center;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media only screen and (min-width: 1000px) {
  .categories-filter-container {
    margin-left: 15%;
    margin-right: 15%;
  }

  .button-category {
    cursor: pointer;
    width: 150px !important;
    height: 150px !important;
    max-width: unset !important;
    max-height: unset !important;
}
}

.button-category.chosen {
background-color: $color-main;
}

.button-category {
  cursor: pointer;
  background-color: white;
  display: flex;
  width: 25vw;
  height: 25vw;
  max-width: 100px;
  max-height: 100px;
  flex-direction: column;
  align-items: center;
  margin: 5px;


.button-category-icon {
  background-color: white;
  margin: 8px 5px;
  height: 50%;
  width: 33%;
  min-height: 30px;
  border-radius: 10px;
  padding: 7px;
  font-weight: 900;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button-category-name {
font-size: 2.4vw;
height: 50%;
width: 100%;

@media only screen and (min-width: 1000px) {
  font-size: 1.5vh;
}

}
}

.search-input {
  width: 85%;
  margin-bottom: 10px;
  margin-top: 7px;
  height: 35px;
  font-size: 15px;
  padding-right: 15px;

  border: 1px solid var(--Border-Default-Default, #D9D9D9)
}


.no-results {
display: flex;
margin-bottom: 20px;
animation: zoom-in-zoom-out 1s ease infinite;
width: 90%;
justify-content: center;
margin-right: auto;
margin-left: auto;
}

.no-results-where-found {
text-align: center;
min-height: 20px;
margin-bottom: 20px;
color: red;
}

@keyframes zoom-in-zoom-out {
0% {
transform: scale(1, 1);
}
50% {
transform: scale(1.05, 1.05);
}
100% {
transform: scale(1, 1);
}
}

.category-chosen-container {
display: flex;
align-items: center;
justify-content: space-evenly;


svg {
width: 30px;
height: 30px;
}

  .back-item{
    cursor: pointer;

  }

.category-chosen-name {
font-size: 30px;
margin-left: 10px;
margin-right: 10px;
}


}

@media screen and (min-width: 800px) {
  // this css will only be used when the screen size is min 800px
  .add-item-button {
    cursor: pointer;
    margin: 0px 25%;
  }
}

.add-item-button {
  cursor: pointer;
  left: 14px;
  bottom: 78px;
  position: fixed;
  z-index: 100;
}


.top-header-search-and-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 20px;


.total-results {
  font-size: 14px;


  }

  .back-container {
    display: flex;
    align-items: center;
    font-size: 18px;

    span {
      margin-right: 10px;
    }

  }
}

.all-button {
  cursor: pointer;
  background-color: white;
  display: flex;
  width: 85%;

  //width: 100vw;
  height: 25vw;
  //max-width: 100px;
  //max-height: 100px;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}