@import "./src/colorDefinitions";

.favorites-container {
  .favorites-page-header {
    text-align: start;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .favorites-title {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    div {
      font-size: 30px;
      margin-left: 10px;
      margin-right: 10px;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .no-favorites-container {
    margin-top: 30px;

    .no-favorites-main {
      font-size: 20px;

    }

    .no-favorites-sub-main {
      margin-top: 20px;
      font-size: 15px;

      svg {
        width: 15px;
        height: 15px;
        margin: 0px 6px;

      }
    }
  }

}


.button-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  margin: 10px 0px;
}

.button-links-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grayBackground;

  svg {
    width: 20px;
  }
}

.button-link-text {
  color: #484848;
}
