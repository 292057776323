.review-container {
  background-color: #bce9ff;
  margin: 20px;
}

.divider-line {
  height: 3px;
  border: none;
  border-radius: 6px;
  background: #C2EAFF;
  width: 100%;
}

.submit-review-container {
  display: flex;
  //justify-content: center;
  //align-items: center;
  flex-direction: column;


  .submit-review-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-review {
    color: #0976BB
  }

  .create-new {
    cursor: pointer;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    svg {
      width: 40px;
      height: 40px;
    }
  }
}