.app-menu-container {
  .app-menu-header {
    margin-top: 31px;
    margin-right: 15px;
    display: flex;
    .logo-menu-header {
      width: 130px;
    }
  }

  .app-menu-body {
    margin-top: 40px;
    margin-bottom: 20px;

    .menu-item-container {
      display: flex;
      height: 50px;
      margin-right: 10px;
      align-items: center;

      svg {
        width: 26px;
        height: 26px;
      }

      .menu-item-text {
        display: flex;
        align-items: center;
        margin-right: 10px;

        font-size: 16px;
        font-weight: 400;
        line-height: 20.93px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;



      }
    }
  }

  .app-menu-main-logo-container {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .app-menu-main-logo {
      width: 80%;
      max-width: 200px;
    }
  }


  .app-menu-footer {
    position: fixed;
    bottom: 0;
    width: 68%;
    font-size: 12px;
    background-color: #54acdc12;
  }
}