.professional-container {
  cursor: pointer;
  //border-radius: 15px;
  padding: 20px;
  //box-shadow: 0 4px 5px 3px rgb(0 0 0 / 35%);
  border: 1px solid #fafafa;
  margin: 10px 5px;
  background-color: white;

  .professional-container-upper {
    display: flex;
    width: 100%;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      margin-left: 10px;
    }

    .professional-name {
      text-align: start;
      font-size: 18px;
      font-weight: bold;
    }

    .user-logo-svg{
      width: 39px;
    }

    .user-logo-cloud {
      width: 39px;
      height: 39px;
      border-radius: 15px;
    }

    .user-logo-cloud.loading {
      background-image: url(../../../assests/spinner.gif);
      border-radius: 0px;
      width: 50px;
      height: 50px;
      background-size: cover;
      content:url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')
    }

  }

  .business-category-container {

  }

  .owner-name {
    font-size: 12px;
    text-align: start;
    margin-top: 10px;
  }

}