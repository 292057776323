.info-container {
  .info-page-header {
    text-align: start;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .info-text {
    white-space: pre-wrap;
    text-align: right;
  }
}