.app-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  height: 49px;
  padding-bottom: 16px;

}

.app-header-right {
  display: flex;
  //align-items: center;
  //justify-content: space-around;
  margin-top: 23px;
  margin-right: 15px;

  div {
    font-weight: 600;
  }

  img {
    margin-right: 10px;
    //height: 72px;
    width: 130px;

  }
}

.logo-header {
  width: 30%;
  cursor: pointer;
}

.hamburger-react {
  z-index: 200 !important;
}

.app-container.shadow {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #5e5e5e;
  opacity: 0.5;
  transition: opacity 2s linear;
}

.app-container-no-menu-side-container {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
}

.menu-side-container.open {
  background-color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  width: 67%;
  height: 100vh;

  animation: run ease 1s;
  -webkit-animation: run ease 1s;
  -moz-animation: run ease 1s;
  -o-animation: run ease 1s;
  -ms-animation: run ease 1s;

}



.menu-side-container.close {
  background-color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 67%;
  height: 100vh;
  margin-left: -98%;

  animation: run_back ease 1s;
  -webkit-animation: run_back ease 1s;
  -moz-animation: run_back ease 1s;
  -o-animation: run_back ease 1s;
  -ms-animation: run_back ease 1s;
}

@media screen and (min-width: 800px) {
  // this css will only be used when the screen size is min 800px
  .menu-side-container.open {
    width: 20%;
  }

  .menu-side-container.close {
    width: 80%;
  }
}

@keyframes run {
  0% { margin-left: -98%;}
  100%{ margin-left: 0%;}
}

@keyframes run_back {
  0% { margin-left: 0%;}
  100%{ margin-left: -98%;}
}

